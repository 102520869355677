import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService:AuthService
) { }


async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  const auth= this.authService.getToken();
  const URL:string = this.router.url;
  console.log('url ---', URL);
  
    if (auth) {
        // check if route is restricted by role
        // if (route.data.roles && route.data.roles.indexOf(currentUser._id) === -1) {
            // role not authorised so redirect to home page
            // this.router.navigate(['auth/signin']);
            // return false;
        // }
        // authorised so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate([`/auth/login`]);
    
    return false;
}
}
