import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  APIURL:string = `${environment.api}/api/v1/`;
	constructor(private http: HttpClient) { }

	postData(data:any, endpoint:any):any {
		console.log('data --', data);
		console.log('endpoint --', this.APIURL+endpoint);

		
		return this.http.post(this.APIURL+endpoint, data)
		.pipe(
			map((responseData:any) => {
				return responseData || [];
			}),
			catchError((error: any) => {
				console.log('post req error --->>>>', JSON.stringify(error))
				return throwError(() => new Error(error.status));
			})
		)
	}

	updateData(data:any, endpoint:any):any {
		return this.http.put(this.APIURL+endpoint, data)
		.pipe(
			map((responseData:any) => {
				return responseData || [];
			}),
			catchError((error: any) => {
				return throwError(() => new Error(error.status));
			})
		)
	}

	getData(endpoint:any):any {
		return this.http.get(this.APIURL+endpoint)
		.pipe(
			map((responseData:any) => {
				return responseData || [];
			}),
			catchError((error: any) => {
				return throwError(() => new Error(error.status));
			})
		)
	}

	getDataById(endpoint:any, ID:any, searchParam?:any):any {
		
		return this.http.get(this.APIURL+endpoint+ID,{ params: searchParam })
		.pipe(
			map((responseData:any) => {
				return responseData || [];
			}),
			catchError((error: any) => {
				return throwError(() => new Error(error.status));
			})
		)
	}

	delete(endpoint:any, ID:any):any {
		return this.http.delete(this.APIURL+endpoint+ID)
		.pipe(
			map((responseData:any) => {
				return responseData || [];
			}),
			catchError((error: any) => {
				return throwError(() => new Error(error.status));
			})
		)
	}

	// get()
}
