import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { ModalController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private router: Router, 
    // public modal: ModalController
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
): Observable<HttpEvent<any>> {
    return next.handle(request)
        .pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
              console.log('------', JSON.stringify(error));
              
                let errorMessage = '';
                // if (error.error instanceof ErrorEvent) {
                //     // client-side error
                //     errorMessage = `Error: ${error.error.message}`;
                //     console.log('error >>>>>', error);
                    
                // } else {
                    // server-side error
                    errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                    if (error.status === 401) {
                      
                      this.router.navigate([`/login`]);
                      // this.modal.dismiss();
                    }
                    if (error.message === 'something went wrong') {
                      this.router.navigate([`/`])
                    }
                // }
                return throwError(errorMessage);
            })
        )
  }
}
