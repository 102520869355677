import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http-service/http-service.service';
export interface User {
	id: string;
	online: string;
	name: string;
	surname: string;
	recivedMessages:number
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService:HttpService) { };
  APIURL= `${environment.api}/api/v1/`;
  

  public signIn(userData: User){
    return this.httpService.postData(userData, 'admin/login');
  }
  public register(userData: User){
    return this.httpService.postData(userData, 'admin/register');
  }
  public forgotPassword(userData: User){
    return this.httpService.postData(userData, 'admin/forget-password');
  }
  public isLoggedIn(){
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }
  public logout(){
    localStorage.removeItem('ACCESS_TOKEN');
  }
  public getToken(){
    return localStorage.getItem('ACCESS_TOKEN') || '';
  }
  public setToken(token:string){
    localStorage.setItem('ACCESS_TOKEN', token);
  }
  public getUser(){
    return (jwtDecode(this.getToken()) as any)?.data;
  }
  public isUser(){
    const user = this.getUser();
    console.log('user --- ', user);
    
    if (user?.name && user?.surname && user?.stateId) {
      return true;
    } else {
      return false;
    }
  }

  public isBusinessUser(){
    const user = this.getUser();
    return user.businessUser
  }
}
