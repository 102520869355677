import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(public authservice: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // console.log('token',this.authservice.getToken(), req)
      req = req.clone({
        setHeaders: {
          // 'Content-Type' : 'multipart/form-data',
          // 'Accept'       : 'multipart/form-data',
          'Authorization': this.authservice.getToken()
        },
      });
  
      return next.handle(req);
    }
  }
