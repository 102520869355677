import { DecimalPipe } from '@angular/common';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/http-service/http-service.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { ORDERDB, OrderDB } from 'src/app/shared/tables/order-list';

@Component({
  selector: 'app-notification',
  // standalone: true,
  // imports: [],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  providers: [TableService, DecimalPipe],
})
export class NotificationComponent {
  public closeResult: string;
  public tableItem$: Observable<OrderDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(public service: TableService, private modalService: NgbModal,
    private httpService:HttpService, private spinner: NgxSpinnerService
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(ORDERDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  ngOnInit() {
    this.getAllAdvPost()
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }
  
  getAllAdvPost(){
    this.httpService.getData('admin/notification')
    .subscribe((res: any) => {
      console.log('getAllNotification',res);
      if (res.ok) {
       this.tableItem$ = res.notification
      }
    }, (err: any) => {
     
    })
  }

  delete(Id:any){
    this.httpService.delete('admin/notification/',Id)
    .subscribe((res: any) => {
      console.log('getAllNotification',res);
      if (res.ok) {
      //  this.tableItem$ = res.notification
      }
    }, (err: any) => {
     
    })
  }
}
