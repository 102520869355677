import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { OrderDB, ORDERDB } from 'src/app/shared/tables/order-list';
import { HttpService } from 'src/app/core/http-service/http-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class OrdersComponent implements OnInit {
  public closeResult: string;
  public tableItem$: Observable<OrderDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(public service: TableService, private modalService: NgbModal,
    private httpService:HttpService, private spinner: NgxSpinnerService
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(ORDERDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getAllAdvPost()
  }

  getAllAdvPost(){
    this.httpService.getData('admin/advposts')
    .subscribe((res: any) => {
      console.log('getAllAdvPost',res);
      if (res.ok) {
       this.tableItem$ = res.posts
      }
    }, (err: any) => {
     
    })
  }

  deletePosts(Id:any){
    this.httpService.delete('admin/advposts/',Id)
    .subscribe((res: any) => {
      console.log('getAllNotification',res);
      if (res.ok) {
      //  this.tableItem$ = res.notification
      }
    }, (err: any) => {
     
    })
  }

  changeStatus(item:any){
    this.httpService.updateData({status:!item.status,_id:item._id},'admin/advposts/')
    .subscribe((res: any) => {
      console.log('getAllNotification',res);
      if (res.ok) {
        item.status = !item.status
      //  this.tableItem$ = res.notification
      }
    }, (err: any) => {
     
    })
  }

}
