<!-- Container-fluid starts-->
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Manage Posts</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <form>
              <div class="mb-3">
                <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText"
                [ngModelOptions]="{standalone: true}" autocomplete="off" />
              </div>
            </form>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" sortable="order_id" (sort)="onSort($event)"> Id</th>
                  <th scope="col" sortable="product" (sort)="onSort($event)">Images</th>
                  <th scope="col" sortable="payment_status" (sort)="onSort($event)">Status</th>
                  <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Summary</th>
                  <th scope="col" sortable="order_status" (sort)="onSort($event)">Order Type</th>
                  <th scope="col" sortable="date" (sort)="onSort($event)">Date</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Price</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of (tableItem$ )">
                  <th scope="row">{{ item.order_id }}</th>
                  <td>
                    <span *ngFor="let image of item.post_image">
                      <img [src]="image" class="img-30 me-2">
                    </span>
                  </td>
                  <td (click)="changeStatus(item)"><span  class="badge {{item.status?'badge-primary':'badge-secondary'}}">{{ item.status }}</span></td>
                  <td>{{ item.summary }}</td>
                  <td><span class="badge {{item.advType == 'sell'?'badge-primary':'badge-secondary'}}">{{ item.advType }}</span></td>
                  <td>{{item.created_at}}</td>
                  <td>{{item.price}}</td>
                  <td><a href="javascript:void(0)" (click)="deletePosts(item._id)">Delete</a></td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                [pageSize]="service.pageSize">
              </ngb-pagination>
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [value]="10" [selected] ="true" [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
